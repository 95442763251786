<template>
  <div class="bg">
    <v-container fill-height fluid>
      <v-row align="center">
        <v-col>
          <v-card
            class="mx-auto"
            max-width="1000"
            min-height="565"
            align="right"
            :id="'main_login_image' + selected_image"
          >
            <v-card
              max-width="350"
              min-height="565"
              align="center"
              class="rounded-l-0"
              flat
            >
              <v-container>
                <v-img
                  class="mt-1"
                  v-if="$vuetify.theme.dark"
                  src="@/assets/LogoV2-White_ClearBknd.png"
                  max-height="100"
                  max-width="200"
                ></v-img>
                <v-img
                  class="mt-1"
                  v-if="!$vuetify.theme.dark"
                  src="@/assets/LogoV2-Black_ClearBknd.png"
                  max-height="100"
                  max-width="200"
                ></v-img>
              </v-container>
              <v-container v-if="login_rp == 'login' && !loading">
                <span
                  style="font-size: 22px"
                  class="d-flex justify-center primary_blue--text mb-10 mt-n2"
                  data-cy="text_customer_portal"
                >
                  Customer Portal
                </span>

                <p data-cy="text_login_to_your_account">
                  Login to your account
                </p>

                <v-form
                  ref="form-login"
                  @submit.prevent.stop="user_login(false)"
                  v-model="isLoginFormValid"
                >
                  <v-text-field
                    v-model="email"
                    label="Email"
                    class="mx-2"
                    outlined
                    type="email"
                    clearable
                    color="primary_blue"
                    :rules="[validateEmail]"
                    data-cy="field_email"
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="password"
                    label="Password"
                    class="mx-2"
                    outlined
                    color="primary_blue"
                    clearable
                    :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (visible = !visible)"
                    :type="visible ? 'text' : 'password'"
                    :rules="[validateNotEmpty]"
                    data-cy="field_password"
                  >
                  </v-text-field>

                  <v-btn
                    elevation="4"
                    large
                    color="primary_blue"
                    type="submit"
                    :disabled="!isLoginFormValid"
                    :dark="isLoginFormValid"
                    data-cy="btn_login"
                  >
                    Login
                  </v-btn>
                  <div class="mt-6">
                    <span
                      class="mr-2 blue_on_hover"
                      style="
                        cursor: pointer;
                        width: 128px;
                        display: inline-block;
                        text-align: right;
                      "
                      @click="
                        $router.push({ path: '/register/create-account' })
                      "
                    >
                      Create Account
                    </span>
                    |
                    <span
                      class="ml-2 blue_on_hover"
                      style="
                        cursor: pointer;
                        width: 128px;
                        display: inline-block;
                      "
                      @click="login_rp = 'fp'"
                      data-cy="href_forgot_password"
                    >
                      Forgot password?
                    </span>
                  </div>
                </v-form>
              </v-container>
              <v-container v-else-if="login_rp == 'login' && loading"
                ><v-progress-circular
                  class="mt-4"
                  v-if="loading"
                  indeterminate
                  size="86"
                  color="primary"
                ></v-progress-circular
              ></v-container>
              <v-stepper
                v-else-if="login_rp == 'fp'"
                v-model="stepperCount"
                class="elevation-0"
              >
                <v-stepper-header class="elevation-0">
                  <v-stepper-step
                    :complete="stepperCount > 1"
                    step="1"
                    color="primary_blue"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="stepperCount > 2"
                    step="2"
                    color="primary_blue"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3" color="primary_blue">
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <!-- Enter email form -->
                  <v-stepper-content step="1" class="my-12">
                    <p data-cy="text_enter_your_email">Enter your email</p>
                    <v-form v-model="isFormOneValid">
                      <v-text-field
                        label="Email"
                        v-model="email_pr"
                        outlined
                        type="email"
                        clearable
                        :rules="[validateEmail]"
                        color="primary_blue"
                        data-cy="field_email"
                      >
                      </v-text-field>
                    </v-form>
                    <v-btn
                      color="primary_blue"
                      @click="onSendCode"
                      large
                      elevation="4"
                      :dark="isFormOneValid"
                      :disabled="!isFormOneValid"
                      data-cy="btn_send_code"
                    >
                      Send Code
                    </v-btn>
                    <br />
                    <p
                      class="mt-4 blue_on_hover"
                      style="cursor: pointer"
                      @click="login_rp = 'login'"
                      data-cy="href_login_instead"
                    >
                      Login instead
                    </p>
                  </v-stepper-content>
                  <!-- Email confirmation code form -->
                  <v-stepper-content step="2">
                    <p data-cy="text_reset_password">Reset your password</p>
                    <p data-cy="text_verification_code_sent">
                      We've sent you a verification code to your email account.
                    </p>
                    <v-form v-model="isFormTwoValid">
                      <v-text-field
                        label="Verifcation code"
                        v-model="verificationCode"
                        color="primary_blue"
                        outlined
                        clearable
                        :rules="[validateIsNumber]"
                        data-cy="field_varification_code"
                      >
                      </v-text-field>
                    </v-form>

                    <v-btn
                      text
                      @click="stepperCount = 1"
                      large
                      elevation="4"
                      class="ma-2"
                      data-cy="btn_back"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="primary_blue"
                      @click="stepperCount = 3"
                      large
                      :disabled="!isFormTwoValid"
                      elevation="4"
                      :dark="isFormTwoValid"
                      data-cy="btn_next"
                    >
                      Next
                    </v-btn>
                    <br />
                    <v-btn
                      @click="onSendCode"
                      class="my-5"
                      text
                      small
                      bottom
                      data-cy="btn_resend_code"
                    >
                      Resend code
                    </v-btn>
                  </v-stepper-content>
                  <!-- Reset password form -->
                  <v-stepper-content step="3" class="my-4">
                    <p class="">Enter your new password</p>
                    <v-form v-model="isFormThreeValid">
                      <v-text-field
                        v-model="newPassword"
                        label="New Password"
                        :rules="[validatePassword]"
                        outlined
                        clearable
                        color="primary_blue"
                        :append-icon="
                          newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                        "
                        @click:append="
                          () => (newPasswordVisible = !newPasswordVisible)
                        "
                        :type="newPasswordVisible ? 'text' : 'password'"
                        data-cy="field_new_password"
                      ></v-text-field>
                      <v-text-field
                        v-model="confirmPassword"
                        color="primary_blue"
                        label="Confirm Password"
                        :rules="[
                          validateSamePassword(
                            this.newPassword,
                            this.confirmPassword
                          ),
                        ]"
                        outlined
                        clearable
                        :append-icon="
                          confirmNewPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                        "
                        @click:append="
                          () =>
                            (confirmNewPasswordVisible =
                              !confirmNewPasswordVisible)
                        "
                        :type="confirmNewPasswordVisible ? 'text' : 'password'"
                        data-cy="field_confirm_password"
                      ></v-text-field>
                      <v-btn
                        text
                        @click="stepperCount = 2"
                        large
                        elevation="2"
                        class="ma-2"
                        data-cy="btn_back"
                      >
                        Back
                      </v-btn>
                      <v-btn
                        color="primary_blue"
                        @click="confirmForgotPassword"
                        large
                        :dark="isFormThreeValid"
                        :disabled="!isFormThreeValid"
                        elevation="2"
                        data-cy="btn_reset"
                      >
                        Reset
                      </v-btn>
                    </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
              <v-container v-else-if="login_rp == 'cc'">
                <span
                  style="font-size: 22px"
                  class="d-flex justify-center primary_blue--text mb-10 mt-n2"
                  data-cy="text_customer_portal"
                >
                  Customer Portal
                </span>
                <p data-cy="text_login_to_your_account">Create a password</p>
                <v-form
                  class="mt-5 mx-4"
                  v-model="password_form_valid"
                  ref="create-password"
                >
                  <!-- <v-text-field
                   
                          v-model="tempPassword"
                          data-testid="current-password"
                          label="Temporary Password"
                          :rules="[validateNotEmpty]"
                          outlined
                          clearable
                          :append-icon="
                            tempPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                          "
                          @click:append="
                            () =>
                              (tempPasswordVisible = !tempPasswordVisible)
                          "
                          :type="tempPasswordVisible ? 'text' : 'password'"
                       
                        ></v-text-field> -->

                  <v-text-field
                    v-model="newPassword"
                    data-testid="new-password"
                    label="New Password"
                    outlined
                    clearable
                    :append-icon="
                      newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    @click:append="
                      () => (newPasswordVisible = !newPasswordVisible)
                    "
                    :type="newPasswordVisible ? 'text' : 'password'"
                    :rules="[validatePassword]"
                  ></v-text-field>

                  <v-text-field
                    v-model="confirmPassword"
                    data-testid="confirm-password"
                    label="Confirm Password"
                    outlined
                    clearable
                    :append-icon="
                      newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    @click:append="
                      () => (newPasswordVisible = !newPasswordVisible)
                    "
                    :type="newPasswordVisible ? 'text' : 'password'"
                    :rules="[
                      validateSamePassword(
                        this.newPassword,
                        this.confirmPassword
                      ),
                    ]"
                  ></v-text-field>
                  <v-checkbox color="primary_blue" v-model="eula_checkbox">
                    <template v-slot:label>
                      <div>
                        I accept the terms of the

                        <a
                          style="color: #003f51"
                          target="_blank"
                          href="https://www.onecup.ai/terms-and-conditions"
                          @click.stop
                        >
                          License Agreement
                        </a>
                        and

                        <a
                          style="color: #003f51"
                          target="_blank"
                          href="https://www.onecup.ai/privacy-policy"
                          @click.stop
                        >
                          Privacy Policy</a
                        >
                      </div>
                    </template>
                  </v-checkbox>

                  <!-- <br />
                  <p
                    class="mt-4"
                    style="color: #003f51; cursor: pointer"
                    @click="login_rp = 'login'"
                    data-cy="href_login_instead"
                  >
                    Login instead
                  </p> -->
                </v-form>
                <v-btn
                  v-if="!eula_checkbox || !password_form_valid"
                  color="primary_blue"
                  @click="user_login(password)"
                  large
                  elevation="4"
                  :disabled="true"
                >
                  Create New Password
                </v-btn>

                <v-btn
                  v-else-if="eula_checkbox && password_form_valid"
                  color="primary_blue"
                  @click="user_login(password)"
                  large
                  elevation="4"
                  :dark="password_form_valid"
                >
                  Create New Password
                </v-btn>
              </v-container>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="error_snackbar"
      v-if="error && error.length"
      :timeout="3000"
      color="primary_blue"
    >
      <span style="font-size: 20px">{{ error }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import {
  validateIsNumber,
  validateSamePassword,
  validateEmail,
  validatePassword,
  validateNotEmpty,
} from "@/utilities/ValidationRules";
import Vue from "vue";
import { CognitoIdentityProviderClient, ForgotPasswordCommand, ConfirmForgotPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
var cognitoidentityserviceprovider = new CognitoIdentityProviderClient({region: process.env.VUE_APP_AWS_REGION});

export default {
  name: "Login",
  components: {},
  data() {
    return {
      selected_image: 0,
      //
      error: null,
      error_snackbar: false,
      //
      email: "",
      visible: false,
      password: "",
      isLoginFormValid: false,
      login_rp: "login",
      //
      stepperCount: 1,
      email_pr: "",
      verificationCode: "",
      newPassword: "",
      confirmPassword: "",
      newPasswordVisible: false,
      tempPassword: "",
      tempPasswordVisible: false,
      confirmNewPasswordVisible: false,
      isFormOneValid: false,
      isFormTwoValid: false,
      isFormThreeValid: false,
      password_form_valid: false,
      loading: false,
      eula_checkbox: false,
    };
  },
  methods: {
    validateIsNumber,
    validateSamePassword,
    validateEmail,
    validatePassword,
    validateNotEmpty,

    async user_login(temp_password) {
      if (!this.$refs["form-login"] && !this.$refs["create-password"]) return;
      this.$store.state.button = false;
      this.loading = true;
      await this.$store
        .dispatch("login", {
          email: this.email,
          password: temp_password.length ? this.newPassword : this.password,
          temp_password: temp_password.length ? this.password : false,
        })
        .then(() => {
          if (this.$store.state.loginError) {
            //  this.error = this.$store.state.loginError;
            if (this.error == "User is not confirmed.") {
              Vue.prototype.$unverifiedUser = this.email;
              this.$router.push({ path: "/register/verify-email" });
            } else if (this.error == "NEW_PASSWORD_REQUIRED") {
              this.error = "Create new password.";
              this.login_rp = "cc";
            }

            //  this.error_snackbar = true;
          }
        });
      this.loading = false;
      return false;
    },

    validate_email(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    onSendCode() {
      var params = {
        ClientId: process.env.VUE_APP_WEB_CLIENT_ID /* required */,
        Username: this.email_pr /* required */,
      };

      var command = new ForgotPasswordCommand(params);
      cognitoidentityserviceprovider.send(command).then(()=>{
        if (this.validate_email(this.email_pr)) {
          this.stepperCount = 2;
        }
      }).catch(err=>{
        this.error = err
        this.error_snackbar = true;
      });

    },
    confirmForgotPassword() {
      var params = {
        ClientId: process.env.VUE_APP_WEB_CLIENT_ID /* required */,
        ConfirmationCode: this.verificationCode /* required */,
        Password: this.newPassword /* required */,
        Username: this.email_pr /* required */,
      };

      var command = new ConfirmForgotPasswordCommand(params);
      cognitoidentityserviceprovider.send(command).then(() =>{
    
        this.login_rp = "login";
      }).catch((err)=>{
        this.error = err;
        this.error_snackbar = true;
      });
    },
  },
  created() {
    this.selected_image = Math.floor(Math.random() * 13);
  },
  computed: {
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    login_error() {
      return this.$store.state.loginError;
    },
  },
  watch: {
 
    login_error() {
      this.error = this.$store.state.loginError;
      this.error_snackbar = true;
    },

    login_rp() {
      this.selected_image = Math.floor(Math.random() * 13);
      if (this.login_rp == "login") {
        (this.stepperCount = 1), (this.email_pr = "");
        this.verificationCode = "";
        this.newPassword = "";
        this.confirmPassword = "";
      } else if (this.login_rp == "fp") {
        this.email = "";
        this.visible = false;
        this.password = "";
      } else {
        this.visible = false;
      }
    },
  },
};
</script>

<style scoped>
.bg {
  background-color: #0090a4;
  height: 100%;
}
#main_login_image1 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image01.png")
    repeat scroll 0% 0%;
}
#main_login_image2 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image02.png")
    repeat scroll 0% 0%;
}
#main_login_image3 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image03.png")
    repeat scroll 0% 0%;
}
#main_login_image4 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image04.png")
    repeat scroll 0% 0%;
}
#main_login_image5 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image05.png")
    repeat scroll 0% 0%;
}
#main_login_image6 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image06.png")
    repeat scroll 0% 0%;
}
#main_login_image7 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image07.png")
    repeat scroll 0% 0%;
}
#main_login_image8 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image08.png")
    repeat scroll 0% 0%;
}
#main_login_image9 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image09.png")
    repeat scroll 0% 0%;
}
#main_login_image10 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image10.png")
    repeat scroll 0% 0%;
}
#main_login_image11 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image11.png")
    repeat scroll 0% 0%;
}
#main_login_image12 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image12.png")
    repeat scroll 0% 0%;
}
#main_login_image0 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image13.png")
    repeat scroll 0% 0%;
}
</style>